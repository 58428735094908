import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import BaffleText from 'components/baffle-text'
import AnimationContainer from 'components/animation-container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReact, faApple, faJava, faNode, faGoogle, faAmazon } from '@fortawesome/free-brands-svg-icons'
import {
  faPencilRuler,
  faHamburger,
  faSmileBeam,
  faChessKnight,
  faQuoteRight,
  faCode,
} from '@fortawesome/free-solid-svg-icons'
import Counter from 'components/counter'
import ThemeContext from '../../context'
import './styles.scss'

class Services extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
    this.show = this.show.bind(this)
  }

  static contextType = ThemeContext

  show() {
    this.setState({ show: true })
  }

  render() {
    return (
      <section
        id={`${this.props.id}`}
        className="services"
        style={{ height: this.context.height }}
      >
        <Row
          className="top"
          style={{
            maxHeight:
              this.context.height !== 'auto'
                ? this.context.height * 0.8
                : 'inherit',
          }}
        >
          <div className="content">
            <Col md={12}>
              <div className="line-text">
                <h4>Services</h4>
              </div>
              <div className="heading">
                <BaffleText
                  text="使用技術"
                  revealDuration={500}
                  revealDelay={500}
                  parentMethod={this.show}
                  callMethodTime={1100}
                />
              </div>
              <div
                className="services_container"
                style={{
                  minHeight:
                    this.context.height !== 'auto'
                      ? this.context.height * 0.6
                      : 'inherit',
                }}
              >
                <Container>{this.services()}</Container>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="bottom">{this.counters()}</Row>
      </section>
    )
  }

  services() {
    if (this.state.show || this.context.height === 'auto') {
      return (
        <Row>
          <Col md={4} className="service">
            <AnimationContainer delay={200} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faReact} />
              </div>
              <h4>Front-end React</h4>
              <p>
                Reactで複雑なFrontendアプリを一人で開発することができます。<br />
                State管理は普段Reduxを使っています。<br />
                スタイルシート言語はSCSSを使っています。<br />
                慣用UIフレームワークはmaterial-UIです。<br />
                バックエンドは普段Firebaseに任せています。<br />
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={400} animation="fadeInDown fast">
              <div className="icon">
                <FontAwesomeIcon icon={faApple} />
              </div>
              <h4>iOS  開発</h4>
              <p>
                Swiftで複雑なiOSアプリを一人で開発することができます。<br />
                SwiftUIとUIKit両方開発経験あり。<br />
                MVCとMVVM両方アプリ設計経験あり。<br />
                Core Dataなどの公式framework使用経験あり。<br />
                バックエンドは普段Firebaseに任せています。<br />
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={600} animation="fadeInRight fast">
              <div className="icon">
                <FontAwesomeIcon icon={faGoogle} />
              </div>
              <h4>Firebase</h4>
              <p>
                iOS/WebアプリにFirebaseを導入した経験あり。<br />
                Firebase Authで認証システムを実装できます。<br />
                Firestoreでデータベースを構築できます。<br />
                Cloud Functionsでバックエンドサーバーの管理ができます。<br />
                Firebase HostingでWebアプリをデプロイできます。<br />
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={800} animation="fadeInLeft fast">
              <div className="icon">
                <FontAwesomeIcon icon={faJava} className="solid" />
              </div>
              <h4>Java</h4>
              <p>
                オラクル認定プロJava開発者です。<br />
                学生時代、Javaに関する開発経験多くあり。<br />
                必要な場合には、Javaの開発スキルをすぐにリフレッシュできます。
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service border-side">
            <AnimationContainer delay={1000} animation="fadeInUp fast">
              <div className="icon">
                <FontAwesomeIcon icon={faNode} className="solid" />
              </div>
              <h4>Node.js(Express)</h4>
              <p>
                npmでLibraryインストールができます。<br />
                routingの基本が分かります。<br />
                簡単なサーバーの構築できます。<br />
                MongoDBとのセッティングができます。
              </p>
            </AnimationContainer>
          </Col>
          <Col md={4} className="service">
            <AnimationContainer delay={1200} animation="fadeInRight fast">
              <div className="icon">
                <FontAwesomeIcon icon={faPencilRuler} className="solid" />
              </div>
              <h4>その他</h4>
              <p>
                アルゴリズムに詳しいです。<br />
                Git/GitHubが使えます。<br />
                Stripe/Plaidによる決済システム実装できます。<br />
                Xcode、VSCodeとUNIX commandに詳しいです。<br />
                sqlの基本知識を持っています。<br />
              </p>
            </AnimationContainer>
          </Col>
        </Row>
      )
    }
  }

  counters() {
    if (this.state.show || this.context.height === 'auto') {
      return (
        <Container>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faChessKnight}
                value={600}
                text="回チェス対局した"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faHamburger}
                value={1000}
                text="ハンバーガー食べた"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faAmazon}
                value={40}
                text="株持ちのアマゾン株主だ"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
          <Col md={3}>
            <AnimationContainer delay={100} animation="fadeIn fast">
              <Counter
                icon={faCode}
                value={40000}
                text="行のコードを書いた"
                symbol="+"
                duration={3}
              />
            </AnimationContainer>
          </Col>
        </Container>
      )
    }
  }
}

export default Services
