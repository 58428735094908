import React from 'react'
import './styles.scss'
import { Row, Col } from 'react-bootstrap'
import AnimationContainer from 'components/animation-container'
import BaffleText from 'components/baffle-text'
import ThemeContext from '../../context'

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",

            phone: "",
            message: "",
            error: false,
            show: false
        }
        this.show = this.show.bind(this)
    }
    static contextType = ThemeContext


    show() {
        this.setState({ show: true })
    }

    check(val) {
        if (this.state.error && val === "") {
            return false
        } else {
            return true
        }
    }

    submit() {
        if (this.state.name === "" || this.state.message === "") {
            this.setState({ error: true })
        } else {
            this.setState({ error: false })
            window.open(`mailto:ryu.liu@alum.utoronto.ca?subject=${this.state.phone}&body=${this.state.message}`)

        }
    }
    render() {
        return (
            <section id={`${this.props.id}`} className="contact" style={{ height: this.context.height }}>
                <Row>
                    <Col md={2} className="side">
                        <h2>
                            <BaffleText text="Contact" revealDuration={500} revealDelay={500} parentMethod={this.show} callMethodTime={1100} />
                        </h2>
                    </Col>
                    <Col md={5} className="form">
                        {this.form()}
                    </Col>
                    <Col md={5} className="map">
                        {this.map()}
                    </Col>
                </Row>
            </section>
        )
    }

    form() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={0} animation="fadeInUp fast">
                    <div className="form-container">
                        <div className="line-text">
                            <h4>Get In Touch</h4>
                            <AnimationContainer delay={50} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" className={`name ${this.check(this.state.name) ? "" : "error"}`} placeholder="お名前" onChange={e => this.setState({ name: e.target.value })} />
                                </div>
                            </AnimationContainer>

                            <AnimationContainer delay={150} animation="fadeInUp fast">
                                <div className="form-group">
                                    <input type="text" className="phone" placeholder="件名" onChange={e => this.setState({ phone: e.target.value })} />
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={200} animation="fadeInUp fast">
                                <div className="form-group">
                                    <textarea className={`message ${this.check(this.state.message) ? "" : "error"}`} placeholder="メッセージ" onChange={e => this.setState({ message: e.target.value })}></textarea>
                                </div>
                            </AnimationContainer>
                            <AnimationContainer delay={250} animation="fadeInUp fast">
                                <div className="submit">
                                    <button className={`hover-button ${this.state.error ? "error" : ""}`} onClick={() => this.submit()}>
                                        <span>Send Email</span>
                                    </button>
                                </div>
                            </AnimationContainer>
                        </div>
                    </div>
                </AnimationContainer>
            )
        }
    }

    map() {
        if (this.state.show || this.context.height === "auto") {
            return (
                <AnimationContainer delay={1000} animation="fadeIn fast" height={this.context.height}>

                    <iframe title="map" width="100%" height="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d207446.2481915965!2d139.60078198715834!3d35.668441463810254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b857628235d%3A0xcdd8aef709a2b520!2z5p2x5Lqs6YO95p2x5Lqs!5e0!3m2!1sja!2sjp!4v1621275526312!5m2!1sja!2sjp" />
                </AnimationContainer>
            )
        }
    }

}

export default Contact